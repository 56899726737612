/* Aplicar efectos de blur y saturación al background del banner */
@supports ((-webkit-backdrop-filter: initial) or (backdrop-filter: initial)) {
    .smart-app-banner {
        -webkit-backdrop-filter: saturate(180%) blur(20px);
        backdrop-filter: saturate(190%) blur(20px);
        background-color: rgba(244,245,247, 0.83);
        /* Fondo translúcido para que el efecto sea visible */
    }
}


.smart-app-banner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.2);
    animation: slide-down 0.3s ease-out;
}

.smart-app-banner {
    z-index: 1000;
    /* Asegúrate de que esté encima de otros elementos */
    animation: slide-down 0.3s ease-out;
}

.download-button {
    transition: background-color 0.2s ease;
}

.close-button {
    background: none;
    border: none;
    cursor: pointer;
}

@keyframes slide-down {
    from {
        transform: translateY(-100%);
    }

    to {
        transform: translateY(0);
    }
}