@font-face {
  font-family: 'Made';
  src: url('../../../assets/fonts/made-light.otf') format('opentype');
  font-weight: 300;
  /* Ligero */
  font-style: normal;
}

@keyframes glower {
  0% {
    filter: blur(15px);
  }

  50% {
    filter: blur(900px);
  }

  100% {
    filter: blur(15px);
  }
}

.app-mobiles {
  position: relative;
  height: 200vh;
  overflow-x: hidden;
  background: linear-gradient(to bottom, #fff, #eaeaea);
}

.mobile-gradient {
  background: transparent !important;
}

.hero-container {

  .hero-text-section {
    max-width: 2000px;
    height: 100vh;
    box-sizing: border-box;
    width: calc(100% - 32px);
    margin: 0 auto;
    padding-bottom: 0px;
    padding-top: 25vh;
    text-align: center;
    font-size: 4vw;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    align-items: center;

    .logo-element {
      margin-bottom: 50px;
      width: 500px;
    }

    .hero-logo-section {
      .logo-top-element {
        width: 50px;
        top: 50px;
        position: fixed;
        mix-blend-mode: difference;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        z-index: 999;
      }
    }

    h1 {
      font-family: 'Made';
      font-weight: 300;
      display: inline-block;
    }

    .download {
      height: auto;
      padding: 14px 48px;
      font-size: 20px;
      margin-top: 20px;
      border: none;
      border-radius: 10px;
      font-family: 'Inter';
      background-color: #111;
      color: #fff;
      cursor: pointer;
      transition: transform 0.2s ease-in-out;
      box-sizing: border-box;
      position: relative;
      transform-style: preserve-3d;
      --blur-value: 300px;
    }

    .download::before {
      content: "";
      position: absolute;
      background: conic-gradient(from 90deg at 40% -25%, #FAE821, #FF6359, #FF5892, #AFA8F9, #457BFF, #90DEFF, #54FF98, #90DEFF, #457BFF, #AFA8F9, #FF5892, #FF6359, #f79d03, #FAE821, #FAE821, #FAE821);
      inset: -3px;
      filter: blur(var(--blur-value));
      transform: translate3d(3px, 0px, -1px);
      border-radius: inherit;
      pointer-events: none;
      animation: glow 2s infinite alternate;
    }

  }

  .hero-media {
    position: relative;
    height: 764px;
  }

  .hero-phone-block {
    position: absolute;
    width: 364px;
    height: 650px;
    top: 0;
    left: 50%;
    transform: translateX(-40%) scale(1.5);

    .hero-phone-template {
      background-position: center;
      background-size: 100%;
      background-repeat: no-repeat;
      width: 100%;
      height: 100%;

      video {
        position: absolute;
        width: 64%;
        height: auto;
        top: 5%;
        left: 9%;
        border-radius: 20px;
        overflow: hidden;
      }
    }
  }

  .hero-usedBy {
    display: flex;
    justify-content: space-between;
    margin-top: 234px;
    padding-top: 234px;
    margin-bottom: 300px;
    margin-left: auto;
    margin-right: auto;
    max-width: 1304px;
    width: calc(100% - 48px);
    position: relative;
    color: white;

    .hero-usedBy-copy {
      margin-bottom: 0;
      padding-left: 52px;
      width: 390px;
      font-size: 20px;
      font-weight: 400;
      line-height: 1.4;
    }
  }
}

.hero-collage {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 35px;
  top: 100vh;
  max-width: 1300px;
  margin: 0 auto;
  padding: 100px 20px;
  position: absolute;
  width: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.hero-element {
  position: relative;
  border: 1px solid #111;
  border-radius: 20px;
  overflow: hidden;
  height: fit-content;
  grid-row: 1;
  z-index: 1;
  opacity: 0;
  transition: transform 0.5s ease-in-out;
}

.hero-element img {
  width: 100%;
  height: auto;
  display: block;
}

.gradient-1 {
  width: 200px;
  height: 250px;
  background-color: #457BFF;
  position: fixed;
  left: 50%;
  top: 50vh;
  transform: translate(-50%, -50%);
  z-index: 0;
  filter: blur(120px);
}

.hero-left-text {
  position: fixed;
  right: 10px;
  width: 47%;
  top: 47vh;
  padding-right: 25%;
  color: #111;
  opacity: 0;
  z-index: 1;
  box-sizing: border-box;

  h1 {
    font-family: 'Made';
    font-weight: 300;
    font-size: 40px;
    margin-bottom: 10px;
    display: inline-block;
  }

  p {
    font-size: 24px;
    margin-top: 10px;
    display: inline-block;
  }
}

.hero-element-0 {
  margin-top: 40px;
}

.hero-element-1 {
  margin-top: -40px;
}

.hero-element-2 {
  margin-top: 80px;
}

.hero-element-3 {
  margin-top: 10px;
}

.loader {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: white;
}

.feature-slides-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: -13vh;

  .feature-slide {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 60%;
    margin: 0 auto;
    margin-right: 35%;
    margin-left: 5%;
    z-index: 5;
    position: relative;

    .feature-slide-title {
      font-family: 'Made';
      font-weight: 300;
      font-size: 48px;
      margin-bottom: 20px;
    }

    .feature-slide-description {
      font-size: 28px;
      margin-top: 10px;
    }

  }

  .feature-slides-right {
    height: 100vh;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: auto;
      height: 70vh;
      opacity: 0;
      transition: all 0.5s ease-in-out;
      position: absolute;
      right: 5%;
      border: 2px solid #111;
      /* Color del borde */
      border-radius: 25px;

      /* Esquinas redondeadas */
      &.as-primary {
        opacity: 1;
      }
    }
  }
}

.background-gradient {
  position: fixed;
  width: 200px;
  height: 300px;
  background: transparent;
  /* Initial color */
  filter: blur(150px);
  opacity: 0;
  z-index: 0;
  /* Ensure it is behind the text and images */
  top: calc(50vh - 150px);
  left: calc(50% - 100px);
  /* Center between columns */
  transition: background 0.5s ease-in-out, opacity 0.3s ease-in-out;
}

.hero-faq {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20vh;
  padding-top: 0;
  .hero-text{
    margin-bottom: 20px;
  }
}

.hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20vh 50vh;
}

.hero-text {
  font-family: 'Made';
  font-weight: 300;
  font-size: 72px;
  margin-bottom: 40px;
}

.hero-subtext {
  font-family: 'Inter';
  font-weight: 500;
  font-size: 54px;
  margin: 10px auto;
  margin-bottom: 50px;
  background: linear-gradient(90deg, #457BFF 0%, #74d5ff 51%, #4bf38e 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
}

.hero-mission {
  padding: 20vh;
  padding-top: 10vh;
  align-items: center;
  display: flex;
  justify-content: center;
}

.hero-mission-text {
  font-family: 'Made';
  font-weight: 300;
  font-size: 54px;
  margin-bottom: 40px;
  line-height: 76px;

  img {
    height: 36px;
  }
}

.download-buttons {
  display: flex;
  gap: 30px;
  align-items: center;
}

.download-button {
  height: 54px;
  /* Ajusta el tamaño de las imágenes según sea necesario */
  cursor: pointer;
}

.logo-element {
  fill: #FAE821;
  /* Color inicial del logo */
  transition: fill 0.3s ease;
  /* Transición suave para el cambio de color */
}
.accordion {
  margin: 30px auto;
  z-index: 2;
}

.accordion-item {
  border-bottom: 1px solid #ddd;
  padding: 10px 0;
}

.accordion-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  font-size: 36px;
  font-family: 'Made';
  cursor: pointer;
  padding: 10px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.accordion-icon {
  font-size: 24px;
  color: #111;
  transition: transform 0.3s ease;
}



.accordion-answer {
  text-align: left;
  overflow: hidden;
  transition: all 0.7s ease, padding 0.7s ease;
  p{
    font-size: 24px;
    font-weight: 500;
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
    .gradient-text-goal{
      ion-icon{
        color: #F6C051;
      }
    }
    .gradient-text-achievement{
      ion-icon{
        color: #EB6BDE;
      }
    }
    ion-icon{
      margin-right: 0px;
      --ionicon-stroke-width: 48px;
      margin-bottom: -3px;
    }
  }
}

ion-icon {
  --ionicon-stroke-width: 48px;
}

.gradient-icon-goal{
  color: #F6C051; /* Color para iconos dentro de la clase .gradient-text-goal */
}

.gradient-icon-achievement{
    color: #EB6BDE;
}

.gradient-icon-habit{
  color: #18bf5b;
}

.gradient-text-goal {
  background: linear-gradient(90deg, #eeca46 0%, #ffb35d 50%, #ff7360 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
}

.gradient-text-habit {
  background: linear-gradient(90deg, #25D86D 0%, #C7DF2A 50%, #FFD435 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
}

.gradient-text-achievement {
  background: linear-gradient(90deg, #ff76a6 0%, #e469f4 50%, #7b6fff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
}

.accordion-answer-open {
  padding: 10px;
}

.accordion-answer-closed {
  max-height: 0;
  padding: 0 10px;
}

.ionicon-stroke-width {
  stroke-width: var(--ionicon-stroke-width, 42px) !important;
}

@media (min-width: 767px) {

  .top-form-mobile,
  .top-form-xl-mobile {
    display: none;
  }
}

/* Tablets pequeñas y dispositivos más grandes (>= 768px) */
@media (max-width: 767px) {
  .feature-slides-container {
    display: block;
    flex-direction: none;
  }

  .top-form-mobile {

    .input,
    .button-top {
      font-size: 14px;
      padding: 7px 12px;
    }
  }

  .pin-spacer {
    padding: 0 !important;
    position: sticky !important;
    height: auto !important;
  }

  .feature-slides-right {
    height: 50vh;
    width: 100%;

    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    z-index: 100;
  }

  .feature-slides-right img {
    height: auto;
    top: 15%;
    z-index: 105 !important;
    border-radius: 25px;
  }

  .background-gradient {
    filter: blur(80px) !important;
    z-index: 0 !important;
    display: none;
  }

  .feature-slide {
    height: auto;
    width: 90% !important;
    margin: 20px 0;
    margin-right: 5% !important;
    margin-left: 5% !important;
    z-index: 0 !important;
  }

  .feature-slide-0 {
    margin-top: 75vh !important;
  }

  .feature-slide-title {
    font-size: 28px;
    margin-bottom: 5px;
  }

  .feature-slide-description {
    font-size: 18px;
    margin-top: 5px;
  }

  .feature-slides-container {
    display: block;
    flex-direction: none;
  }

  .feature-slides-left {
    padding: 20px;
  }

  .feature-slides-right {
    transform: translate(0px, 0px) !important;
    height: 75vh !important;
    order: 1;
    position: fixed;
    top: 0 !important;
    left: 0 !important;
    width: 100%;
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 85%, rgba(255, 255, 255, 0) 100%);
    justify-content: center;
    align-items: center;
  }

  .button-top-xl,
  .input-top-xl {
    left: 50%;
    width: 270px;
    z-index: 999;
    padding: 10px 10px;
    font-size: 16px;
    height: 42px;
    position: absolute;
  }

  .top-form-xl {
    display: none;
  }

  .top-form-xl-mobile {
    position: relative;
    width: max-content;
    right: 0px;
    top: 25px;
    z-index: 50;
  }

  .feature-slides-right img {
    width: auto;
    margin-top: 10vh;
    margin-bottom: 5vh;
    top: 0;
    height: 55vh !important;
    right: auto !important;
    border-radius: 25px;
  }

  .feature-slide {
    height: auto;
    width: 100%;
    margin: 20px 0;
  }

  .feature-slide-title {
    font-size: 28px;
    margin-bottom: 5px;
  }

  .feature-slide-description {
    font-size: 18px;
    margin-top: 5px;
  }

  .hero-container {
    & .hero-text-section {
      .logo-element {
        margin-bottom: 50px;
        width: 300px;
      }

      h1 {
        font-family: 'Made';
        font-weight: 300;
        font-size: 24px;
        display: inline-block;
      }
    }
  }

  .hero-container {
    & .hero-text-section {
      .logo-element {
        margin-bottom: 50px;
        width: 300px;
      }

      h1 {
        font-family: 'Made';
        font-weight: 300;
        font-size: 24px;
        display: inline-block;
      }

      & .hero-logo-section {
        .logo-top-element {
          width: 50px;
          top: 30px;
          position: fixed;
          mix-blend-mode: difference;
          left: 40px;
          z-index: 999;
        }
      }
    }
  }

  .hero-element {
    position: relative;
    border: 0.5px solid #111;
    border-radius: 7px;
  }

  .input-top-xl {
    left: 0;
    width: 0px;
  }

  .hero-collage {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 7px;
    top: 100vh;
    max-width: 1200px;
    margin: 0 auto;
    padding: 100px 0px !important;
    position: absolute;
    width: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .feature-slides-container {
    margin-top: 0 !important;

    & .feature-slide {
      height: 100vh !important;

      .feature-slide-title {
        font-size: 24px;
        margin-bottom: 10px;
      }

      .feature-slide-description {
        font-size: 18px;
        margin-top: 10px;
      }
    }
  }

  .hero-faq {
    padding-top: 0vh !important;
    .hero-subtext {
      font-size: 20px;
    }
    .hero-text {
      margin-bottom: 0px;
    }
    .accordion{
      margin-top: 0px;
    }
    .accordion-question{
      font-size: 24px;
      ion-icon {
        display: none;
      }
    }
    .accordion-answer{
      p{
        font-size: 16px;
        margin-block-start: 1em;
        margin-block-end: 1em;
      }
    }
  }

  .hero,
  .hero-mission, .hero-faq {
    padding: 15vh 5vh;

    .download-button {
      height: 36px;
      cursor: pointer;
  }
    h1 {
      font-size: 24px;
      line-height: 40px;
    }
    .hero-mission-text{
      font-size: 18px;
      svg {     width: 130px;
        height: 16px;
        margin-bottom: -1px;}
    }
  }

  .top-form {
    display: none;
  }

  .top-form-mobile {
    position: fixed;
    width: max-content;
    top: 30px;
    opacity: 0;
    z-index: 999;
    right: 0px !important;
    transform: translate(0px) !important;

    .button-top {
      right: 15px !important;
      width: 250px;
      left: auto;
      transform: translate(0px) !important;
      top: -15px;
    }

    div {
      left: 0;
      position: fixed;
    }
  }

}


/* Tablets grandes y pantallas de escritorio (>= 992px) */
@media (min-width: 992px) {
  .hero-mission-text {
    font-size: 32px;
  }

  .feature-slides-container {
    & .feature-slide {
      .feature-slide-title {
        font-size: 48px;
        margin-bottom: 30px;
      }

      .feature-slide-description {
        font-size: 28px;
        margin-top: 10px;
      }
    }
  }
}

/* Pantallas grandes y extra grandes (>= 1200px) */
@media (min-width: 1200px) {
  .hero-mission-text {
    font-size: 44px;
    line-height: 76px;
  }

  .feature-slides-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-top: -13vh;

    .feature-slide {
      height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 60%;
      margin: 0 auto;
      margin-right: 35%;
      margin-left: 5%;
      z-index: 5;
      position: relative;

      .feature-slide-title {
        font-family: 'Made';
        font-weight: 300;
        font-size: 72px;
        margin-bottom: 10px;
      }

      .feature-slide-description {
        font-size: 32px;
        margin-top: 10px;
      }

    }
  }
}