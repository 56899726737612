.lineMask {
    overflow: hidden;
}

.lineMask p {
    margin: 0px;
}

.ionicon-stroke-width {
    stroke-width: var(--ionicon-stroke-width, 40px) !important;
}
  

ion-icon {
    visibility: hidden;
    margin-bottom: -2px;
    margin-right: 3px;
}

.main {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    position: relative;

    
    .logoTopElement {
        width: 10%;
        max-width: 60px;
        min-width: 35px;
        top: 50px;
        position: fixed;
        mix-blend-mode: difference;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 999;
    }

    .title {
        position: absolute;
        left: 50%;
        top: 45%;
        transform: translate(-50%, -50%);
        text-align: center;
        width: 90%;

        p {
            color: grey;
            margin: 0px;
            text-align: center;
            margin-top: 10px;
        }
    }

    .textRotator {
        font-size: 2vw; // Ajusta el tamaño según tus necesidades
    }

    .rotatingWord {
        padding: 0 0.6vw;
        color: #111;
        display: inline-block;
    }


    .plane {
        opacity: 1;
        width: 100%;
        height: 100%;
        position: absolute;

        img {
            position: absolute;
            transition: all 0.3s ease; // Suaviza los cambios en tamaño y posición.
        }

        &:nth-of-type(1) {
            img {
                &:nth-of-type(1) {
                    left: 80%;
                    top: 70%;
                    width: clamp(10vw, 20%, 300px);
                }

                &:nth-of-type(2) {
                    left: 5%;
                    top: 65%;
                    width: clamp(10vw, 20%, 380px);
                }

                &:nth-of-type(3) {
                    left: 25%;
                    top: 5%;
                    width: clamp(10vw, 20%, 265px);
                }
            }
        }

        &:nth-of-type(2) {
            img {
                &:nth-of-type(1) {
                    left: 5%;
                    top: 5%;
                    width: clamp(10vw, 20%, 280px);
                }

                &:nth-of-type(2) {
                    left: 83%;
                    top: 5%;
                    width: clamp(10vw, 20%, 280px);
                }

                &:nth-of-type(3) {
                    left: 60%;
                    top: 70%;
                    width: clamp(10vw, 20%, 285px);
                }

                &:nth-of-type(4) {
                    left: 5%;
                    top: 40%;
                    width: clamp(10vw, 20%, 215px);
                }
            }
        }

        &:nth-of-type(3) {
            img {
                &:nth-of-type(1) {
                    left: 65%;
                    top: 2.5%;
                    width: clamp(10vw, 20%, 250px);
                }

                &:nth-of-type(2) {
                    left: 30%;
                    top: 75%;
                    width: clamp(10vw, 20%, 240px);
                }

                &:nth-of-type(3) {
                    left: 85%;
                    top: 40%;
                    width: clamp(10vw, 20%, 270px);
                }
            }
        }
    }



    @media (max-width: 1240px) {
        .plane {
            &:nth-of-type(1) {
                img {
                    &:nth-of-type(1) {
                        display: none;
                    }

                    &:nth-of-type(2) {
                        left: 5%;
                        top: 65%;
                        width: clamp(10vw, 15%, 450px);
                    }

                    &:nth-of-type(3) {
                        left: 20%;
                        top: 5%;
                        width: clamp(10vw, 15%, 295px);
                    }
                }
            }

            &:nth-of-type(2) {
                img {
                    &:nth-of-type(1) {
                        left: 5%;
                        top: 10%;
                        width: clamp(10vw, 15%, 310px);
                    }

                    &:nth-of-type(2) {
                        left: 83%;
                        top: 10%;
                        width: clamp(10vw, 15%, 310px);
                    }

                    &:nth-of-type(3) {
                        left: 60%;
                        top: 70%;
                        width: clamp(10vw, 15%, 315px);
                    }

                    &:nth-of-type(4) {
                        display: none;
                    }
                }
            }

            &:nth-of-type(3) {
                img {
                    &:nth-of-type(1) {
                        left: 65%;
                        top: 2.5%;
                        width: clamp(10vw, 15%, 280px);
                    }

                    &:nth-of-type(2) {
                        left: 30%;
                        top: 75%;
                        width: clamp(10vw, 15%, 270px);
                    }

                    &:nth-of-type(3) {
                        left: 80%;
                        top: 70%;
                        width: clamp(10vw, 15%, 300px);
                    }
                }
            }
        }

        .title {
            top: 47%;
        }

        .textRotator {
            font-size: 3vw; // Ajuste para el texto rotativo en dispositivos móviles
        }
    }

    @media (max-width: 768px) {
        .plane {

            &:nth-of-type(1) {
                img {
                    &:nth-of-type(1) {
                        left: 75%;
                        top: 60%;
                        display: block;
                        width: clamp(15vw, 22%, 510px);
                    }

                    &:nth-of-type(2) {
                        left: 7%;
                        top: 60%;
                        width: clamp(15vw, 22%, 310px);
                    }

                    &:nth-of-type(3) {
                        left: 7%;
                        top: 5%;
                        width: clamp(15vw, 22%, 310px);
                    }
                }
            }

            &:nth-of-type(2) {
                img {
                    &:nth-of-type(1) {
                        left: 7%;
                        top: 20%;
                        width: clamp(15vw, 22%, 310px);
                    }

                    &:nth-of-type(2) {
                        left: 78%;
                        top: 27%;
                        width: clamp(15vw, 22%, 310px);
                    }

                    &:nth-of-type(3) {
                        left: 76%;
                        top: 85%;
                        width: clamp(15vw, 22%, 310px);
                    }

                    &:nth-of-type(4) {
                        left: 43%;
                        top: 18%;
                        display: block;
                        width: clamp(15vw, 22%, 310px);
                    }
                }
            }

            &:nth-of-type(3) {
                img {
                    &:nth-of-type(1) {
                        left: 75%;
                        top: 5%;
                        width: clamp(15vw, 22%, 310px);
                    }

                    &:nth-of-type(2) {
                        left: 10%;
                        top: 85%;
                        width: clamp(15vw, 22%, 310px);
                    }

                    &:nth-of-type(3) {
                        left: 45%;
                        top: 70%;
                        width: clamp(15vw, 22%, 310px);
                    }
                }
            }
        }

        .title {
            top: 47%;
        }

        .textRotator {
            font-size: 3vw; // Ajuste para el texto rotativo en dispositivos móviles
        }
    }
}

.habitsMain {

    height: auto;
    width: 100vw;
    overflow: hidden;
    position: relative;

    .habitsPlane {
        opacity: 1;
        width: 100%;
        height: 100%;
        position: absolute;

        img {
            position: absolute;
            
        }

        &:nth-of-type(1) {
            img {
                &:nth-of-type(1) {
                    left: 15%;
                    top: 65%;
                }
            }
        }

        &:nth-of-type(2) {
            img {
                &:nth-of-type(1) {
                    left: 33%;
                    top: 7%;
                }
            }
        }
    }


}