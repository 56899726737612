@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-Light.ttf') format('truetype');
  font-weight: 300;
  /* Ligero */
  font-style: normal;
}

@font-face {
  font-family: 'Title';
  src: url('./assets/fonts/AkzidenzGrotExtBQ-Medium.ttf') format('truetype');
  font-weight: 400;
  /* Ligero */
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-Regular.ttf') format('truetype');
  font-weight: 400;
  /* Normal */
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-Medium.ttf') format('truetype');
  font-weight: 500;
  /* Medio */
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-SemiBold.ttf') format('truetype');
  font-weight: 600;
  /* Semibold */
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-Bold.ttf') format('truetype');
  font-weight: 700;
  /* Negrita */
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  /* Extra Bold */
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-Black.ttf') format('truetype');
  font-weight: 900;
  /* Negro */
  font-style: normal;
}

:focus-visible {
  outline: 0;
}

body {
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  margin: 0px;
  padding: 0px;
  max-width: 100%;
  background: #fff;
  color: #222;
  box-sizing: border-box;
}

li {
  font-size: 16px;
  line-height: 1.5;
  margin-block-start: 1em;
  margin-block-end: 1em;
}

.container {
  margin: 70px 20%;
  padding-bottom: 150px;
  min-height: 100vh;
}

@media only screen and (max-width: 600px) {
  .container {
    margin: 10%;
  }

  .form-row {
    flex-direction: column;
  }

  .input {
    width: 100%;
    margin-bottom: 10px;
  }

  .mission-container>p {
    font-size: 30px !important;
  }

  .mission-container {
    padding: 40px !important;
  }
}

@media screen and (max-width: 768px) {
  .footer-menu {
    flex-direction: column;
    /* Cambiamos a una disposición de columna */
    align-items: center;
    /* Centramos los elementos */
  }

  .footer-column {
    flex-basis: 100%;
    /* Ocupa todo el ancho en dispositivos pequeños */
    margin-bottom: 20px;
    /* Añadimos espacio entre columnas */
  }

}

@media screen and (max-width: 1024px) {
  .footer {
    background: #111 !important;
    background-image: none;
    height: auto !important;
    position: inherit !important;
    display: flex;
  }

  .footer-menu {
    text-align: left;
    align-items: flex-start;
  }

  .rightColumn {
    width: 100% !important;
  }

  .leftColumn {
    width: 100% !important;
    text-align: center !important;
  }

  .parallaxContent {
    position: inherit !important;
    margin-bottom: 0 !important;
  }

  .input-radio {
    margin-bottom: 7px;
  }
}

/* Estilos para la página de inicio */
.home-container {
  text-align: center;
}

/* Estilos para los títulos */
h1 {
  font-size: 36px;
  margin-bottom: 20px;
  margin-top: 0;
  font-family: 'Title', sans-serif;
}

/* Estilos para los párrafos */
p {
  font-size: 16px;
  line-height: 1.5;
}

.bottom-links {
  position: absolute;
  bottom: 25px;
  left: 0;
  width: 100%;
  text-align: center;
}

/* Estilos para los enlaces */
a {
  color: #222;
  text-decoration: none;
    z-index: 1;

}

a:hover {
  text-decoration: underline;
}

.load {
  mix-blend-mode: screen;
}

.home-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  background-image: url('./assets/background.jpg');
  background-size: cover;
  background-position: center;
  overflow-x: hidden;
  overflow-y: hidden;
}

.column-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.mission-container {
  background: #000;
  color: #fff;
  padding: 50px;
  display: block;
  min-height: 100vh;
  width: 100%;
  box-sizing: border-box;
}

.mission-container>p {
  font-size: 40px;
  font-family: 'Inter-Medium', sans-serif;
}

.center-container {
  text-align: center;
}

header {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 25px;
}

.header-logo {
  width: 70px;
  margin-bottom: 5px;
  height: auto;
}

.logo {
  width: 85%;
  height: auto;
}

.parallaxContent {
  position: relative;
  z-index: 2;
  box-sizing: border-box;
  margin-bottom: 350px;
  background-color: white;
}

.footer {
  background: url('./screens/logo3dfooter.svg'), #111;
  background-size: contain;
  background-position: left;
  background-repeat: no-repeat;
  position: fixed;
  bottom: 0;
  z-index: 0;
  width: 100%;
  height: 350px;
  color: #fff;
}

.footer-menu {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}

.footer-column {
  flex: 1;
  padding: 0 20px;
}

.footer-column h3 {
  font-size: 18px;
}

.footer-column ul {
  list-style: none;
  padding: 0;
}

.footer-column ul li {
  margin-bottom: 10px;
}

.footer-column ul li a {
  color: white;
  text-decoration: none;
}

.rightColumn {
  width: 60%;
  padding: 20px;
  box-sizing: border-box;
  float: right;
  text-align: left;
}

.leftColumn {
  width: 40%;
  padding: 20px;
  box-sizing: border-box;
  float: left;
  text-align: left;
}

.form-row {
  display: flex;
  align-items: center;

}

.input-container {
  width: 0;
  opacity: 0;
}

.input {
  height: 45px;
  width: 0;
  opacity: 0;
  padding: 12px 18px;
  font-size: 16px;
  border: none;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
  font-family: 'Inter';
  color: #111;
  box-sizing: border-box;
}

.form-row.step-1 {
  width: 300px;
  opacity: 1;
}

.button {
  height: 45px;
  padding: 12px 18px;
  font-size: 16px;
  border: none;
  border-radius: 10px;
  font-family: 'Inter';
  background-color: #222;
  color: #fff;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  box-sizing: border-box;
  position: relative;
  transform-style: preserve-3d;
  transition: all 0.3s ease-out;
}

.button::before {
  content: "";
  position: absolute;
  inset: -3px;
  background: conic-gradient(from 90deg at 40% -25%, #FAE821, #FF6359, #FF5892, #AFA8F9, #457BFF, #90DEFF, #54FF98, #90DEFF, #457BFF, #AFA8F9, #FF5892, #FF6359, #f79d03, #FAE821, #FAE821, #FAE821);
  filter: blur(10px);
  transform: translate3d(3px, 0px, -1px);
  border-radius: inherit;
  pointer-events: none;
  animation: glow 2s infinite alternate;
}

@keyframes glow {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.error-message {
  height: 40px;
  padding: 12px 18px;
  border: none;
  border-radius: 10px;
  font-family: 'Inter';
  background-color: #FF4A4A;
  color: #fff;
  font-size: 14px;
  margin-top: 5px;
  box-sizing: border-box;
  animation: skew-x-shaking 0.75s linear 2 forwards;
}

.error-message::after {
  content: " ";
  visibility: visible;
  opacity: 1;
  position: absolute;
  bottom: 100%;
  left: 10%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #FF4A4A transparent;
}

@keyframes skew-x-shaking {
  0% {
    transform: skewX(-15deg);
  }

  5% {
    transform: skewX(15deg);
  }

  10% {
    transform: skewX(-15deg);
  }

  15% {
    transform: skewX(15deg);
  }

  20% {
    transform: skewX(0deg);
  }

  100% {
    transform: skewX(0deg);
  }
}

/* Animación de desvanecimiento */
.fade-out {
  opacity: 0;
}

.success {
  color: #222 !important;
  background-color: #fff !important;
  position: relative;
  /* Ajustamos la posición a absoluta */
  left: 50%;
  /* Posicionamos el borde izquierdo del botón al centro */
  transform: translateX(-50%);
  /* Desplazamos el botón hacia la izquierda en un 50% de su propio ancho */
  transition: all 0.3s ease-out;
}

.email-sent {
  height: 40px;
  padding: 12px 18px;
  border: none;
  border-radius: 10px;
  font-family: 'Inter';
  background-color: #222;
  color: #fff;
  font-size: 14px;
  text-align: center;
  margin-top: 0px;
  box-sizing: border-box;
  animation: fadeIn 0.7s forwards;
}

.suc {
  opacity: 0;
  margin-top: 60px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* .email-sent::after {
  content: " ";
  position: absolute;
  bottom: 100%;
  left: 10%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #222 transparent;
  opacity: 1;
} */

.device-selection {
  padding: 5px 10px;
  width: 280px;
  box-sizing: border-box;
}

.device-title {
  margin-top: 0;
  margin-bottom: 5px;
  font-family: 'Inter';
  font-weight: 700;
  font-size: 14px;
}

.input-radio {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

[type="radio"]:checked+label,
[type="radio"]:not(:checked)+label {
  position: relative;
  padding-left: 25px;
  margin-right: 0px;
  cursor: pointer;
  line-height: 20px;
  font-size: 15px;
  display: inline-block;
  color: #222;
}

[type="radio"]:checked+label:before,
[type="radio"]:not(:checked)+label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #222;
  border-radius: 100%;
  background: #fff;
}

[type="radio"]:checked+label:after,
[type="radio"]:not(:checked)+label:after {
  content: '';
  width: 20px;
  height: 20px;
  background: #222;
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

[type="radio"]:not(:checked)+label:after {
  opacity: 0;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
}

[type="radio"]:checked+label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.device-selection button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.device-selection button:hover {
  background-color: #0056b3;
}

.fade-out {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.device-selection {
  opacity: 0;
  /* Establecer la opacidad inicial a 0 */
  transition: opacity 0.5s ease-in-out;
  /* Agregar una transición de opacidad */
}

.fade-in {
  opacity: 1;
  /* Cambiar la opacidad a 1 para mostrar con efecto fade-in */
}







form {
  position: relative;
  max-width: 300px;
  /* Ajusta el ancho máximo según tu diseño */
}

.form-slide {
  position: absolute;
  top: 0;
  width: 0;
  opacity: 0;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  transition: all 1s ease-in-out;
}

.form-slide.active {
  left: 0;
}


button {
  padding: 10px 20px;
  color: #fff;
  border: none;
  position: relative;
  cursor: pointer;
  left: 50%;
  box-sizing: border-box;
  transform: translate(-50%, -50%);
  transition: background-color 0.3s ease-in-out;
}

.input-top {
  height: 40px;
  width: 0;
  opacity: 0;
  padding: 7px 18px;
  font-size: 15px;
  border: none;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
  font-family: 'Inter';
  color: #111;
  border: 1px solid #ddd;
  box-sizing: border-box;
}

.button-top {
  height: 40px;
  padding: 7px 18px;
  font-size: 15px;
  border: none;
  border-radius: 10px;
  font-family: 'Inter';
  font-weight: 500;
  background-color: #fff;
  color: #111;
  border: 1px solid #111;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  box-sizing: border-box;
  position: relative;
  transform-style: preserve-3d;
  transition: all 0.3s ease-out;
}

.button-top-xl, .input-top-xl {
  left: 50%;
  width: 300px;
  z-index: 999;
  padding: 10px 14px;
  font-size: 18px;
  height: 48px;
  position: absolute;
}

.button-top-xl {
  top: 24px;
  opacity: 0;
  --blur-value: 300px;
}

.button-top-xl::before {
  filter: blur(var(--blur-value));
}

.top-form-xl {
  position: relative;
  width: max-content;
  right: 0px;
  top: 25px;
  z-index: 50;
}

.button-top-right {
  --blur-value: 300px;
}

.button-top-right::before {
  filter: blur(var(--blur-value));
}

.top-form {
  position: fixed;
  width: max-content;
  right: 30px;
  top: 50px;
  opacity: 0;
  z-index: 50;
}